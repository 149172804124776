@use "../../../utils/styles/themeStyles" as themeStyles;

.container {
  height: 100%;
  width: 100%;
  color: themeStyles.$text-black-color;
}

.navigationItemsListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
}

.navigationItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  height: 70px;
  min-width: 50px;
  width: fit-content;
  padding: 0 15px;
  cursor: pointer;
  box-sizing: border-box;


  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;
    text-align: center;
    text-decoration: none;
    color: themeStyles.$text-black-color;
  }
}


.activeNavigationItemRoute {
  > a {
    border-radius: 4px;
    background: themeStyles.$top-bar-active-nav-background-color;
    padding: 10px;
    font-weight: bolder;
  }
}

.navigationItemButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style: none;

  > a {
    padding: 10px 25px;
    text-decoration: none;
    color: themeStyles.$primary-white-color;
    background: themeStyles.$top-bar-button-pro-background-color;
    border-radius: 4px;
  }
}

@media (min-width: themeStyles.$screen-sm-break-point) {
  .navigationItemsListContainer {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .navigationItem {
    height: 100%;
    width: fit-content;
    align-items: center;
    justify-content: center;
  }
}
@use "../../../utils/styles/themeStyles" as themeStyles;

.container {
  > span {
    font-size: small;
    height: 20px;
  }
}

.inputField {
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: 3px solid themeStyles.$white;
  background-color: transparent;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  &:hover,
  &:focus,
  &:active {
    border: 3px solid white;
    outline-color: transparent;
  }
}

.inputFieldValidationError {
  border: 3PX solid themeStyles.$red-error-color;
  box-sizing: border-box;

  &:hover,
  &:focus {
    border: 3PX solid themeStyles.$red-error-color;
  }
}

.errorColor {
  color: themeStyles.$red-error-color;
}

.successColor {
  color: themeStyles.$green-success-color;
}

.inputFieldValidationSuccess {
  color: themeStyles.$green-success-color;
  box-sizing: border-box;

  &:hover,
  &:focus {
    color: themeStyles.$green-success-color;
  }
}
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 70px 20px;
  position: relative;
  > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 1800px;
  }
}

@use "../../../utils/styles/themeStyles" as themeStyles;

.container {
  width: 60px;
  height: 100%;
  margin: auto 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

  > div {
    width: 90%;
    height: 4px;
    margin: 5px ;
    background-color: themeStyles.$top-bar-toggle-button-background-color;
  }
}


@media (min-width: themeStyles.$screen-sm-break-point) {
  .container {
    display: none;
  }
}


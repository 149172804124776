@use "../../../utils/styles/themeStyles" as themeStyles;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > span {
    font-size: small;
    height: 20px;
  }

}

.textAreaField {
  flex-grow: 1;
  border: none;
  border-bottom: 3px solid themeStyles.$white;
  background-color: transparent;
  padding: 0;
  margin:  0;
  resize: none;
  box-sizing: border-box;

  &:hover,
  &:focus,
  &:active {
    border: 3px solid themeStyles.$white;
    outline-color: transparent;
  }
}

.textAreaFieldValidationError {
  border: 3PX solid themeStyles.$red-error-color;
  box-sizing: border-box;

  &:hover,
  &:focus {
    border: 3PX solid themeStyles.$red-error-color;
  }
}

.textAreaFieldValidationSuccess {
  color: themeStyles.$green-success-color;
  box-sizing: border-box;

  &:hover,
  &:focus {
    color: themeStyles.$green-success-color;
  }
}
.errorColor {
  color: themeStyles.$red-error-color;
}

.successColor {
  color: themeStyles.$green-success-color;
}

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > img {
    height: 50px;
    width: 50px;
  }

  > a {
    text-decoration: none;
    color: inherit;
  }
}

@use "../../utils/styles/themeStyles" as themeStyles;

$about-me-md-max-width: 850px;
$about-me-sm-max-width: 480px;

.container {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 75%;
  mnin-height: 320px;
  color: themeStyles.$black7;
  margin: 10px 0;

  @media (max-width: $about-me-md-max-width) {
    flex-direction: column;
    width: 90%;
  }

  @media (max-width: $about-me-sm-max-width) {
    flex-direction: column;
    width: 100%;
  }

}

.containerColumnReversed  {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 75%;
  mnin-height: 320px;
  color: themeStyles.$black7;
  margin: 10px 0;

  @media (max-width: $about-me-md-max-width) {
    flex-direction: column-reverse;
    width: 90%;
  }

  @media (max-width: $about-me-sm-max-width) {
    flex-direction: column-reverse;
    width: 100%;
  }
}

.imageContainer {
  display: flex;
  width: 35%;
  color: themeStyles.$white;
  position: relative;
  padding: 10px;

  &:hover > .rowImageOverlay {
    visibility: visible;
    opacity: 1;
    transition: opacity .4s, visibility .4s;
  }

  &:hover > .rowImage {
    -webkit-transform: rotate(360deg) scale(0);
    -ms-transform: rotate(360deg) scale(0);
    transform: rotate(.0turn) scale(0);
  }

  @media (max-width: $about-me-md-max-width) {
    width: 70%;
  }

}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 10px;

  @media (max-width: $about-me-md-max-width) {
    width: 100%;
  }
}

.rowTitle {
  text-transform: capitalize;
  font-family: 'Montserrat';
  font-style: normal;
  text-shadow: -8px 8px 13px themeStyles.$black4;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.rowImage {
  display: block;
  width: 100%;
  height: auto;
  -webkit-transform: rotate(360deg) scale(1);
  -ms-transform: rotate(360deg) scale(1);
  transform: rotate(360deg) scale(1);
  -webkit-transition: .3s ease;
  transition: .3s ease;
}

.rowImageOverlay {
  position: absolute;
  text-decoration: none;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: themeStyles.$blueviolet4;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity .4s, visibility .34s;
  cursor: pointer;
}

.rowImageOverlayText {
  color: themeStyles.$white;
  font-size: 1rem;
}
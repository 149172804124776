@use "../../../utils/styles/themeStyles" as themeStyles;

.container {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: themeStyles.$black6;
}
@use "../../../utils/styles/themeStyles" as themeStyles;
.container {
  height: 70px;
  display: flex;
  background: themeStyles.$top-bar-background-color;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;

  > div {
    height: 100%;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > h4 {
    text-align: start;
    margin: 0;
    padding: 0;
  }
}

.navigationLinkContainer {
  display: none;
  width: fit-content;
}

@media (min-width: themeStyles.$screen-sm-break-point) {
  .navigationLinkContainer {
    display: flex;
  }
}

$blueviolet: blueviolet; // border ...
$blueviolet2: #8743FF;
$blueviolet3: #4136F1; // border colors for buttons, ...
$blueviolet4: #5E58F8; // image border background
$grey1: #F7F5FA; // background main one
$grey2: #D1D4DD; //top app background color
$grey3: #DAD9DE; // for the side back background
$black: #242424; // mostly test color
$black2: #292524;
$black3: rgba(0, 0, 0, 0.25);
$black4: rgba(0, 0, 0, 0.35);
$black5: rgba(20, 102, 204, 0.16); // basic button box shadow
$black6: rgba(0, 0, 0, 0.5); // backdrop color
$black7: #2C2C2C;
$black8: rgba(0, 0, 0, 0.30);
$black9: rgba(0, 0, 0, 0.22);
$linear-gradient-blue-violet: linear-gradient(270deg,#8743ff,#4136f1);
$linear-gradient-blue-violet-second-color: linear-gradient(270deg, #8743FF 0%, #4136F1 100%);
$white: #FFFFFF;
$grey4: #cecaca; // app bar background active color
$red-error-color: rgb(226, 95, 95);
$red-error-color-2: rgb(218, 115, 115);
$green-success-color: rgb(138, 204, 138);
$green-success-color-2: rgb(151, 218, 151);
$blue: #1DA1F2;
$blue2: #2867B2;
$radial-yellow-to-pink: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);



// sizes
$screen-sm-break-point: 768px;
$screen-md-break-point: 900px;
$screen-lg-break-point: 1200px;
$screen-xl-break-point: 1536px;

// common
$text-black-color: $black;
$primary-white-color: $white;
$section-heading-color: $black4;

// usages
$primary-background: $grey1;

$top-bar-background-color: $grey2;
$top-bar-navigation-item-border-color: $blueviolet2;
$top-bar-active-nav-item-color: $blueviolet2;
$top-bar-active-nav-background-color: $grey4;
$top-bar-button-pro-background-color: $blueviolet2;
$top-bar-toggle-button-background-color: $blueviolet2;

$side-bard-background-color: $grey3;

$home-page-digitize-text-shadow-color: $black3;

$basic-button-color: $linear-gradient-blue-violet-second-color;
$basic-button-box-shadow-color: $black5;

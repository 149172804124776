@use "../../../utils/styles/themeStyles" as themeStyles;

.container {
  width: 100%;
  padding: 0;
  color: themeStyles.$white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.imageContainer {
  display: block;
  background: themeStyles.$blueviolet4;
  width: 100%;

  > img {
    display: block;
    width: 100%;
    position: relative;
  }
}

.imageContainer svg {
  display: block;
  width: 100%;
}

.socialMediaContainer {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-flow: column;
  min-width: 200px;
  background-color: themeStyles.$blueviolet4;
  min-height: 270px;
  justify-content: space-between;
  padding: 20px 20px 20px 10px;
}

.socialMediaIconContainer {
  display: flex;

  > a {
    background: unset;
    color: unset;
    margin-right: 5px;
  }
}

.socialMediaIconItemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 3px solid themeStyles.$white;
  border-radius: 100%;
  cursor: pointer;

  > svg {
    -webkit-transition: -webkit-transform .8s ease-in-out;
    transition: transform .8s ease-in-out;
    &:focus,
    &:hover {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }

  }
}


.twitterIcon {
  background: themeStyles.$blue;
}

.instagramIcon {
  background: themeStyles.$radial-yellow-to-pink;
}

.gitHubIcon {
  background: themeStyles.$black7;
}

.linkedinIcons {
  background: themeStyles.$blue2;
}

.copyright {
  position: absolute;
  bottom: 10px;
  align-self: flex-end;
}

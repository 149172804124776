@use "../../utils/styles/themeStyles" as themeStyles;

.btn {
  box-sizing: border-box;
  padding: 15px;
  margin: 0;
  border: transparent;
  color: themeStyles.$primary-white-color;
  text-transform: capitalize;
  text-decoration: none;
  background: themeStyles.$linear-gradient-blue-violet-second-color;
  box-shadow: 0px 15px 30px themeStyles.$black5;
  cursor: pointer;
  min-width: 120px;

  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
    font-weight: bold;
    box-shadow: none;
    outline-style: none;
    color: themeStyles.$black;
    border: 2px solid themeStyles.$blueviolet3;
    background: none;
  }

}


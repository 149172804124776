@use "../../../utils/styles/themeStyles" as themeStyles;

$about-me-md-max-width: 850px;
$about-me-sm-max-width: 480px;
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 700px;
  height: 300px;
}

.fieldsContainer {
  display: flex;
  align-items: center;
  height: 100%;




  @media (max-width: $about-me-md-max-width) {
    flex-direction: column;
  }
}

.inputFieldContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-between;
  margin: 0 20px;
  height: 100%;

  @media (max-width: $about-me-md-max-width) {
    width: 80%;
    margin: 0;
    height: fit-content;

    > div {
      > input {
        margin-bottom: 20px;
      }
    }


  }

  @media (max-width: $about-me-sm-max-width) {
    width: 100%;
  }

}

.textAreaContainer {
  width: 50%;
  height: 100%;

  @media (max-width: $about-me-md-max-width) {
    width: 80%;
    height: 180px;
  }

  @media (max-width: $about-me-sm-max-width) {
    width: 100%;
  }
}
.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.messageResultContainer {
  font-size: small;
  align-self: center;
  box-sizing: border-box;
  padding:  10px;
  color: themeStyles.$white;
}

.errorMessageResult {
  background-color: themeStyles.$red-error-color-2;
}

.successMessageResult {
  background-color: themeStyles.$green-success-color-2;
}
.container {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100vh;
}

.mainContent {
  min-height: 70vh;
}




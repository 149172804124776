@use "../../../utils/styles/themeStyles" as themeStyles;

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  //margin: 15px 0 0 15px;
  width: 300px;
  min-height: 450px;
  padding: 10px;
  background-color: themeStyles.$white;
  border-radius: 4px;
  border: 1px solid rgba(179, 187, 193, 0.25);
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px 0px;
  -webkit-box-flex: 1;
}

.headingContainer {
  display: flex;
  height: 100px;
  align-items: center;
  flex-direction: column;

  > h4 {
    margin: 10px 0 0;
    padding: 0;
  }
}

.headingImageContainer {
  width: 50px;
  height: 50px;

  > img {
    width: 100%;
    height: 100%;
  }
}

.cardDivider {
  width: 95%;
  height: 1px;
  align-self: center;
  background-color: rgba(179, 187, 193, 0.25);
}

.descriptionContainer {
  padding: 20px;
}

.cardDescription {
  height: 120px;
}

.serviceSpecificationContainer {
  margin-top: 20px;
  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.serviceItem {
  display: flex;
  margin-bottom: 10px;

  > li {
    margin-left: 10px;
  }
}

.contactContainer {
  position: absolute;
  bottom: 0;
  padding: 20px;
  box-sizing: border-box;
  font-weight: 600;
  color: themeStyles.$blueviolet3;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
@use "../../../utils/styles/themeStyles" as themeStyles ;

.container {
  position: fixed;
  width: 80%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  max-width: 500px;
  background-size: cover;
  padding: 36px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  background-image: url("../../../assests/home/laptopMobileBlurred.jpg");
  background-position-x: right;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
}

@media (min-width: themeStyles.$screen-sm-break-point) {
  .container {
    display: none;
  }
}

.openSideBar {
  transform: translateY(0);
}

.closeSideBar {
  transform: translateX(-100%);
}
